import React from 'react';
import Slider2 from './Slider2';

const slideImages = [
    require('./../../images/main-slider/slider2/slide1.jpg'),
    require('./../../images/main-slider/slider2/slide2.jpg'),
    require('./../../images/main-slider/slider2/slide3.jpg')
];

const HomeSlider = () => {
    return <Slider2 slides={slideImages} />;
};

export default HomeSlider;
