import React from 'react';

class Slider2 extends React.Component {
    componentDidMount() {
        // Load Revolution Slider script
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
                // Don't remove the script immediately – let it load first!
            });
        };

        loadScript('./assets/js/rev-script-2.js').catch(console.error);
    }

    renderSlides = () => {
        const { slides } = this.props;

        return slides.map((slide, index) => (
            <li
                key={index}
                data-index={`rs-${70 + index}`}
                data-transition="fade"
                data-slotamount="default"
                data-hideafterloop={0}
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed={300}
                data-thumb={slide}
                data-rotate={0}
                data-saveperformance="off"
            >
                {/* MAIN IMAGE */}
                <img
                    src={slide}
                    alt=""
                    data-bgcolor="#f8f8f8"
                    data-bgposition="center center"
                    data-bgfit="cover"
                    data-bgrepeat="no-repeat"
                    data-bgparallax="off"
                    className="rev-slidebg"
                    data-no-retina
                />
                {/* Overlay Layer */}
                <div
                    className="tp-caption tp-shape tp-shapewrapper rs-parallaxlevel-tobggroup"
                    data-x="['right','right','right','center']"
                    data-hoffset="['0','0','0','0']"
                    data-y="['middle','middle','middle','middle']"
                    data-voffset="['0','0','0','0']"
                    data-width="['full','full','full','full']"
                    data-height="['full','full','full','full']"
                    data-type="shape"
                    data-basealign="slide"
                    data-responsive_offset="off"
                    data-responsive="off"
                    data-frames='[{"from":"opacity:0;","speed":1500,"to":"o:1;","delay":150,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power2.easeInOut"}]'
                    style={{ zIndex: 6, backgroundColor: 'rgba(0,0,0,0.5)' }}
                />
            </li>
        ));
    };

    render() {
        return (
            <div
                id="rev_slider_one_wrapper"
                className="rev_slider_wrapper fullscreen-container"
                data-alias="mask-showcase"
                data-source="gallery"
                style={{ background: '#aaaaaa', padding: 0 }}
            >
                <div
                    id="rev_slider_one"
                    className="rev_slider fullscreenbanner"
                    style={{ display: 'none' }}
                    data-version="5.4.1"
                >
                    <ul>{this.renderSlides()}</ul>
                    <div
                        className="tp-bannertimer"
                        style={{ height: 10, background: 'rgba(0, 0, 0, 0.15)' }}
                    />
                </div>
            </div>
        );
    }
}

export default Slider2;
