import React, { useEffect, useRef } from "react";

const CardStackCarousel = () => {
  const containerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    const cardList = containerRef.current.querySelector(".card-list");

    function goNext() {
      const lastCard = cardList.lastElementChild;
      lastCard.classList.add("transformThis");

      const secondLast = cardList.children[cardList.children.length - 2];
      if (secondLast) {
        secondLast.classList.add("activeNow");
      }

      setTimeout(() => {
        lastCard.classList.remove("transformThis", "activeNow");
        cardList.insertBefore(lastCard, cardList.firstElementChild);
      }, 150);
    }

    // Start auto-rotation every 8 seconds
    intervalRef.current = setInterval(goNext, 5000);

    // Cleanup on unmount
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const contentForGuests = {
    title: `Graced by Greatness`,
    content: `We are honored to have welcomed some truly remarkable individuals through our doors — visionaries, artists, designers, and patrons who share our passion for brilliance and beauty.
              Their visits have added a unique sparkle to our journey, and we are proud to have been a part of theirs. \n
              Each guest has left behind more than admiration — they’ve inspired us to shine brighter.`
  };

  return (
    <div className="aboutcontainer" ref={containerRef}>
         <div className="row p-4">
            <h3 className="fw-bold mb-3">{contentForGuests.title}</h3>
            <p className="mb-0">{contentForGuests.content}</p>
          </div>
          {/* Image Section */}
          <div className="card-stack-wrapper">
            <div className="card-stack">
              <ul className="card-list">
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g1.png`)}
                    alt="Card 1"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g2.png`)}
                    alt="Card 2"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g3.png`)}
                    alt="Card 3"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g5.png`)}
                    alt="Card 4"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g6.png`)}
                    alt="Card 5"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g7.png`)}
                    alt="Card 6"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g8.png`)}
                    alt="Card 7"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g9.png`)}
                    alt="Card 8"
                  />
                </li>
                <li className="card">
                  <img
                    src={require(`../../images/switches/products/g4.png`)}
                    alt="Card 9"
                  />
                </li>
              </ul>
            </div>

       </div>  
    </div>
  );
};

export default CardStackCarousel;
