import React from "react";
import Header from "../Common/Header";
import Footer from "../Elements/FooterComponent";
import ZigZagLayout from "../Elements/ZigZagLayout";
import Banner from "../Elements/Banner";
import StackedSlider from "../Elements/AboutSlider";
import CardStackCarousel from "../Elements/AboutSlider";

const About = () => {
  return (
    <>
      <Header />
      <Banner
        imageUrl={require(`../../images/switches/products/aboutfinal.jpg`)}
        altText="Switches Banner"
        showEnquire={true}
        enquireUrl="/contact"
        bannerText={`Built on Brilliance, Driven by Legacy`}
        textColor="black"
      />
      <div className="py-4 my-4">
        <ZigZagLayout
          image={require(`../../images/switches/products/about1.jpg`)}
          title={`From Vision to Legacy: The Turbo Lights Journey`}
          content="Established In 2011, Turbo Lights was launched, entering the LED manufacturing space. Over the years, it expanded into premium residential sectors and built a strong dealership network. Today, Turbo Lights caters to private and public sectors, including PSUs such as SIDBI, SEBI, NSE, and RTI."
          reverse
       />
        <ZigZagLayout
          image={require(`../../images/projects/axtria/5.jpeg`)}
          title="Beyond Lighting: Our Vision for Every Space"
          content="Our vision is to be a leading provider of innovative and sustainable lighting solutions across corporate, residential, and industrial infrastructure. Our mission is to deliver functional, efficient, and well-engineered lighting systems, backed by reliable service, technical expertise, and attention to detail, while ensuring consistency in performance and execution across all scales of projects."
         
        />
        <div className="container">
        <CardStackCarousel />
      </div>
      </div>
      
      <Footer />
    </>
  );
};

export default About;
