import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import About1 from './../Elements/About1';
import ClientsLogo1 from './../Elements/ClientsLogo1';
import Creations from '../Elements/Creations';
import FeaturedCollection from '../Elements/FeaturedCollection';
import Dealers from '../Elements/Dealers';
import HomeSlider from '../Elements/HomeSlider';

class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <HomeSlider />
                    <About1 />
                    <Creations />
                    <ClientsLogo1 />
                    <FeaturedCollection />
                    <Dealers />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home1;