// components/ZigZagLayout.jsx
import React from "react";

const ZigZagLayout = ({ image, title, content, reverse = false }) => {
  return (
    <div className={`container customabout`}>
      <div className={`row align-items-center ${reverse ? "flex-row-reverse" : ""}`}>
        {/* Image Section */}
        <div className="col-md-6 mb-md-0 p-4">
          <img
            src={image}
            alt={title}
            className="img-fluid rounded shadow"
            style={{ width: "100%", height: "auto", objectFit: "cover" }}
          />
        </div>

        {/* Content Section */}
        <div className="col-md-6">
          <h3 className="fw-bold mb-3">{title}</h3>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default ZigZagLayout;
